.reset-password {
	margin: 0 auto;
	padding: 40px 0;
	width: 320px;

	&--error &__error-msg,
	&--success &__success-wrapper {
		display: block;
	}

	&--success &__form-wrapper {
		display: none;
	}

	&__logo {
		display: block;
		margin: 0 auto 40px auto;
		width: 320px;
		height: 92px;
		overflow: hidden;
		text-align: left;
		text-indent: -99em;
		background-size: 320px 92px;
		background-position: 0 0;
		background-repeat: no-repeat;
		background-image: url('assets/logo.png');
	}

	&__title {
		margin: 0 0 40px 0;
		font-size: 18px;
		text-transform: uppercase;
		text-align: center;
	}

	&__form-list {
		margin: 0 0 10px 0;
		padding: 0;
	}

	&__form-list-item {
		list-style: none;
		padding: 0 0 20px 0;
	}

	&__form-item-label {
		display: block;
		margin: 0 0 5px 0;
	}

	&__form-item-text {
		margin: 0;
		padding: 15px;
		width: 100%;
		height: 45px;
		font-size: 15px;
		line-height: 1;
		border: 1px solid #efefef;
		background: #fafafa;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
	}

	&--error &__form-item-text {
		border-color: #B43435;
	}

	&__submit {
		width: 100%;
		height: 48px;
		border: none;
		text-transform: uppercase;
		text-align: center;
		font-size: 16px;
		line-height: 1;
		color: #fff;
		background: #fbb332;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		cursor: pointer;
		border: none;
		outline: none;
		&:hover {
			background: darken(#fbb332, 10%);
		}
	}

	&__error-msg {
		display: none;
		margin: 0 0 20px 0;
		padding: 20px;
		font-size: 16px;
		text-align: center;
		color: #ffffff;
		background: #B43435;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
	}

	&__success-wrapper {
		display: none;
		padding: 140px 0 0 0;
		width: 100%;
		background-color: transparent;
		background-size: 80px 80px;
		background-position: 50% 0;
		background-repeat: no-repeat;
		background-image: url('assets/ico-success.png');
	}

	&__success-wrapper-p {
		margin: 0 0 20px 0;
		text-align: center;
		font-size: 16px;
	}

}

@media
all and (-webkit-min-device-pixel-ratio : 1.5),
all and (-o-min-device-pixel-ratio: 3/2),
all and (min--moz-device-pixel-ratio: 1.5),
all and (min-device-pixel-ratio: 1.5) {

	.reset-password {

		&__logo {
			background-image: url('assets/logo@2x.png');
		}

		&__success-wrapper {
			background-image: url('assets/ico-success@2x.png');
		}

	}

}

@media (max-width: 360px) {

	.reset-password {

		&__form-wrapper {
			padding: 0 20px;
		}

		&__success-wrapper {
			padding: 140px 20px 0 20px;
		}

	}

}